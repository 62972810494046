/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect, useMemo, useState, useRef } from "react";
import s from "./index.module.css";
// import { Image, Shimmer } from "react-shimmer";
import cn from "classnames";
import { Box, Text, Button, Switch } from "@components/common";
import Avatar from "@components/avatar";
import Heading from "@components/heading";
import { AiOutlineDelete } from "react-icons/ai";
import { FaCamera } from "react-icons/fa"; // Import camera icon
import {
  fData, fetchValidRoleName,
  formatDate,
  generateInitials,
  toBase64,
  validateEmail,
  validatePhoneNumber,
} from "@utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { BlacklistDialog, DeleteDialog } from "@features/Utils";
import auth from "@models/query/auth";
import FormProvider from "@forms/FormProvider";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Contact, SupportedRole, UpdateUser } from "models/query/system";
import TextWrapper from "@forms/TextWrapper";
import countryList from "react-select-country-list";
import {
  useDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useRemoveUserMutation,
  useSupportRoles,
  useUpdateRoleMutation,
  useUpdateUserMutation,
  useUser,
} from "services/system/users";
import SelectWrapper from "@forms/SelectWrapper";
import FileWrapper from "@forms/FileWrapper";
import defaultProfileImage from '@assets/images/default-profile.png'; // Adjust this import based on your project structure
import { StylesConfig } from 'react-select';

type Props = {
  data: auth.RootObject | undefined;
  error: any;
  isLoading: boolean;
} & WizardChildRef;

const statusOptions = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false },
];

const idTypes = [
  { label: "Voter's ID", value: "Voters" },
  { label: "National ID", value: "National" },
  { label: "Passport", value: "Passport" },
  { label: "NHIS", value: "NHIS" },
  { label: "Drivers License", value: "Drivers" },
];

const MAX_FILE_SIZE = 3 * 1000 * 1000; // 3 Mb
const FILE_FORMATS = ["image/jpg", "image/jpeg", "image/webp", "image/png"];

// Add this helper function at the top of your file
const getUserRoleData = (user: auth.RootObject | undefined, field: string) => {
  if (!user || !user.role) return undefined;
  const roleName = fetchValidRoleName(user.role.role);
  const roleData = (user as any)[roleName];
  if (!roleData) return undefined;

  // Handle nested properties
  const fieldParts = field.split('.');
  return fieldParts.reduce((obj, part) => obj && obj[part], roleData);
};

const Information: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [IDImage, setIDImage] = useState("");
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const navigate = useNavigate();
  const countryOptions = useMemo(() => countryList().getData(), []);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { generateModal } = useModalContext();
  let { id } = useParams();

  const { user, isLoading: userLoading, error: userError } = useUser(id || "");

  const { mutate: updateUser, isLoading: updating } = useUpdateUserMutation(() => {
    setEdit(false);
    setIsFormDirty(false);
  });

  const { mutate: updateRole, isLoading: roleUpdating } = useUpdateRoleMutation(() => {
    setEdit(false);
    setIsFormDirty(false);
  });

  const roleOptions = useMemo(() => {
    const roles = [
      {
        name: 'systemAdministrator',
        humanizedName: 'System Admin',
        description: 'System Administrators'
      },
      {
        name: 'corporateAdministrator',
        humanizedName: 'Corporate Admin',
        description: 'Corporate Administrators'
      }
    ];

    return roles.map(role => ({
      value: role.name,
      label: role.humanizedName,
    }));
  }, []);

  const userValidationSchema = Yup.object().shape({
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    countryCode: Yup.string().nullable(),
    identificationDocumentId: Yup.string().nullable(),
    identificationType: Yup.string().nullable(),
    email: Yup.string().email("Enter a valid email address").nullable(),
    phone: Yup.string().nullable(),
    city: Yup.string().nullable(),
    role: Yup.string().nullable(),
    isEnabled: Yup.boolean().nullable(),
    isBlacklisted: Yup.boolean().nullable(),
    identificationMedia: Yup.mixed().nullable(),
  });

  const defaultValues: FieldValues = {
    firstName: "",
    lastName: "",
    countryCode: "",
    identificationDocumentId: "",
    identificationType: "",
    email: "",
    phone: "",
    role: "",
    isEnabled: false,
    city: "",
    identificationMedia: "",
    isBlacklisted: false,
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(userValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        const base64 = await toBase64(file);
        setProfileImage(base64 as string);
        setValue('profileImage', base64, { shouldDirty: true }); // Add this line
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  const onSubmit = async (formData: typeof defaultValues) => {
    let contacts: Contact[] = [];
    if (formData.phone) {
      contacts.push({
        isPrimary: true,
        type: "mobile",
        value: formData.phone,
      });
    }
    if (formData.email) {
      contacts.push({
        isPrimary: true,
        type: "email",
        value: formData.email,
      });
    }

    let payload: UpdateUser = {
      firstName: formData.firstName || undefined,
      lastName: formData.lastName || undefined,
      countryCode: formData.countryCode || undefined,
      city: formData.city || undefined,
      identificationDocumentId: formData.identificationDocumentId || undefined,
      identificationType: formData.identificationType || undefined,
      identificationMedia: IDImage ? {
        contentType: "application/json",
        fileName: "Any",
        url: IDImage,
      } : undefined,
      contacts: contacts,
      profileImage: profileImage || undefined,
    };

    if (user) {
      updateUser({
        variables: {
          data: payload,
          id: Number(id),
        },
      });

      // If the role has changed, update it separately
      if (formData.role && formData.role !== user.role.role) {
        updateRole({
          variables: {
            id: Number(id),
            role: formData.role,
          },
        });
      }
    }

  };
  const { mutate: disableUser, isLoading: disabling } =
    useDisableUserMutation();
  const { mutate: enableUser, isLoading: enabling } = useEnableUserMutation();
  const { mutate: removeBlacklist, isLoading: blacklist } =
    useRemoveUserMutation();
  const { mutate: deleteUser, isLoading: deleting } = useDeleteUserMutation();

  const handleUserStatus = (id: number, action: string) => {
    if (action === "enable") {
      enableUser({
        variables: {
          id,
        },
      });
    }
    if (action === "disable") {
      disableUser({
        variables: {
          id,
        },
      });
    }
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.identificationMedia) {
        toBase64(value.identificationMedia[0])
          .then((image: unknown) => setIDImage(image as string))
          .catch(console.log);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);



  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        countryCode: user.country?.code || "",
        identificationDocumentId: getUserRoleData(user, "identificationDocumentId") || "",
        identificationType: getUserRoleData(user, "identificationType")?.label || "",
        role: user.role.supportedUserRole || "",
        phone: user.contacts.find((e: any) => e.type === "mobile")?.value || "",
        email: user.contacts.find((e: any) => e.type !== "mobile")?.value || "",
        city: user.city || "",
        isBlacklisted: user.isBlacklisted || false,
        identificationMedia: getUserRoleData(user, 'identificationMedia.url') || "",
        isEnabled: user.isEnabled || false,
      });
    }
  }, [user]);

  const generateInitials = (name: string) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const renderProfileImage = () => {
    const name = user ? `${user.firstName} ${user.lastName}` : 'User';
    const imageUrl = profileImage || user?.profileImage;
    const initials = generateInitials(name);

    return (
      <div className={s.profileImageContainer}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={name}
            className={s.profileImage}
          />
        ) : (
          <Avatar
            initials={initials}
            color="yellow"
            className={s.avatarImage}
          />
        )}
        {edit && (
          <div 
            className={s.cameraIconContainer}
            onClick={() => fileInputRef.current?.click()}
          >
            <FaCamera className={s.cameraIcon} />
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="hidden"
        />
      </div>
    );
  };
  

  const renderIDImage = () => {
    const imageUrl = getUserRoleData(user, 'identificationMedia.url');
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="ID Document"
          className={s.idImage}
        />
      );
    } else {
      return <Text className={s.noIdText}>No ID document uploaded</Text>;
    }
  };

  return (
    <div className={cn(s.root, "px-3 lg:px-3")}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box className={cn("section no-border flex lg:flex-row flex-col")}>
          {renderProfileImage()}
          <Box form className={cn("flex flex-col w-full")}>
            <Box
              className={
                "items-center flex lg:flex-row flex-col gap-4 lg:gap-0"
              }
            >
              <Box col className={"w-full lg:w-auto"}>
                <Heading size="lg100" className={"text-left"}>
                  {user
                    ? `${user.firstName} ${user.lastName}`
                    : userError && !userLoading
                      ? "Error"
                      : "..."}
                </Heading>
                <Text Component={"label"} className={"gi-subtitle"}>
                  Added on{" "}
                  {user
                    ? `${formatDate(new Date(user.createdOn))}`
                    : userError && !userLoading
                      ? "Error"
                      : "..."}
                </Text>
              </Box>

              <Box className={"flex flex-row ml-auto w-full lg:w-auto"}>
                {edit ? (
                  <>
                    <a
                      className="btn btn-md mt-5"
                      onClick={() => {
                        setEdit(false);
                        reset();
                      }}
                      type={"button"}
                      role={"button"}
                    >
                      Cancel
                    </a>
                    <Button
                      variant="fill"
                      pill
                      disabled={!isFormDirty || updating || roleUpdating} // Change this line
                      type={"submit"}
                      className="btn-md"
                      label={updating || roleUpdating ? "Saving..." : "Save"}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      variant="icon"
                      className="btn-md"
                      onClick={() => setEdit((current) => !current)}
                      label={"Edit User"}
                      type={"button"}
                    />
                    <Button
                      variant="fill"
                      pill
                      className="!px-6"
                      type={"button"}
                      label={"Delete User"}
                      onClick={() =>
                        generateModal(
                          "Delete User",
                          <DeleteDialog
                            prompt=" Are you sure you want to delete the user"
                            boldText={user ? user.firstName : ""}
                            callback={() =>
                              user
                                ? deleteUser({
                                  variables: {
                                    id: user.id,
                                  },
                                })
                                : null
                            }
                            loading={deleting}
                          />,
                          "center"
                        )
                      }
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box row className={"section !py-0"}>
          <Box className={cn("lg:mr-[13px] !hidden lg:!flex", s.avatar)} />
          <Box col className={cn("w-full section no-border !py-0")}>
            {edit && (
              <>
                <Box row className={"gi-field w-full"}>
                  <Text Component={"label"} className={s.label}>
                    First Name
                  </Text>
                  <TextWrapper
                    name={"firstName"}
                    color="primary"
                    placeholder="Farmall Corp"
                    className={"mb-4"}
                  />
                </Box>
                <Box row className={"gi-field w-full"}>
                  <Text Component={"label"} className={s.label}>
                    Last Name
                  </Text>
                  <TextWrapper
                    name={"lastName"}
                    color="primary"
                    placeholder="Farmall Corp"
                    className={"mb-4"}
                  />
                </Box>
              </>
            )}
            <Box row className={"gi-field w-full"}>
              <Text Component={"label"} className={s.label}>
                ID Number
              </Text>
              {edit ? (
                <TextWrapper
                  name={"identificationDocumentId"}
                  color="primary"
                  placeholder="10605488"
                  className={s.input}
                />
              ) : (
                <Text className={"gi-subtitle"}>
                  {getUserRoleData(user, "identificationDocumentId") || "Not provided"}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field w-full"}>
              <Text Component={"label"} className={s.label}>
                ID Type
              </Text>
              {edit ? (
                <SelectWrapper
                  name={"identificationType"}
                  className={"mb-4 input "}
                  placeholder={"Select ID Type"}
                  options={idTypes}
                  value={idTypes.find(
                    (i) => i.value === methods.getValues("identificationType")
                  )}
                  onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as any;
                    if (user) {
                      setValue("identificationType", value.value, { shouldDirty: true });
                    }
                  }}
                />
              ) : (
                <Text className={"gi-subtitle"}>
                  {getUserRoleData(user, "identificationType")?.label || "Not provided"}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field w-full"}>
              <Text Component={"label"} className={s.label}>
                ID Document
              </Text>
              {edit ? (
                <FileWrapper
                  name={"identificationMedia"}
                  className={"mb-4 block"}
                  label={"Upload ID Image"}
                  variant={"outline"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      toBase64(file)
                        .then((base64String) => {
                          setIDImage(base64String as string);
                          setValue("identificationMedia", base64String as string, { shouldDirty: true });
                        })
                        .catch(console.error);
                    }
                  }}
                />
              ) : (
                renderIDImage()
              )}
            </Box>
          </Box>
        </Box>
        <Box row className={"section"}>
          <Box className={cn("lg:mr-[13px] !hidden lg:!flex", s.avatar)} />
          <Box col className={cn("w-full section no-border")}>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                Status
              </Text>
              <Text className={"gi-subtitle"}>
                {" "}
                {user
                  ? `${user.status === "ACTIVE" ? "Active" : "Disabled"}`
                  : userError && !userLoading
                    ? "Error"
                    : "..."}
              </Text>
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                Role
              </Text>
              {edit ? (
                <SelectWrapper
                  name="role"
                  className={"mb-4 input"}
                  placeholder={user?.role?.role || "Select Role"}
                  options={roleOptions}
                  value={roleOptions.find((option) => option.value === user?.role?.role)}
                  onChange={(selectedOption: any) => {
                    setValue("role", selectedOption.value, { shouldValidate: true });
                  }}
                  isDisabled={user?.role?.role === 'systemAdministrator'}
                />
              ) : (
                <Text className={s.subtitle}>
                  {roleOptions.find(option => option.value === user?.role?.role)?.label || 'No Role Assigned'}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"}>
                {user && user.isBlacklisted ? "Unblacklist" : "Blacklist"} this
                user
              </Text>

              <Box row alignItems={"center"}>
                <Switch
                  checked={user ? user.isBlacklisted : false}
                  variant="inner-slider"
                  onChange={() => {
                    if (user)
                      if (user.isBlacklisted) {
                        generateModal(
                          "Unblacklist User",
                          <DeleteDialog
                            prompt={
                              "Are you sure you want to unblacklist this user?"
                            }
                            callback={() =>
                              removeBlacklist({
                                variables: {
                                  id: user.id,
                                },
                              })
                            }
                            loading={blacklist}
                            text={"Remove Blacklist"}
                          />,
                          "center"
                        );
                      } else {
                        generateModal(
                          "Blacklist User",
                          <BlacklistDialog id={user.id} />,
                          "center"
                        );
                      }
                  }}
                />
                {edit ? (
                  <Button
                    variant="icon"
                    onClick={() => navigate("/app/blacklist-category")}
                    className={cn(s.link, "ml-4")}
                  >
                    View Blacklist
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  variant="icon"
                  onClick={() => navigate("/app/blacklist-category")}
                  className="!text-primary ml-2"
                >
                  View Blacklist
                </Button>
              </Box>
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                User Access
              </Text>
              <SelectWrapper
                name={"isEnabled"}
                className={"mb-4 input "}
                placeholder={"Select User Access"}
                options={statusOptions}
                isLoading={userLoading}
                value={statusOptions.find(
                  (i) => i.value === methods.getValues("isEnabled")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as any;
                  if (user) {
                    if (value.value === false) {
                      handleUserStatus(user.id, "disable");
                    }
                    if (value.value === true) {
                      handleUserStatus(user.id, "enable");
                    }
                    setValue("isEnabled", value.value);
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box row className={"section no-border flex flex-col"}>
          <Box className={cn("lg:mr-[13px] !hidden lg:!flex", s.avatar)} />
          <Box
            col
            className={cn("w-full section no-border flex flex-col", s.textBox)}
          >
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                City
              </Text>
              {edit ? (
                <TextWrapper
                  name={"city"}
                  color="primary"
                  placeholder="Enter city"
                  className={s.input}
                />
              ) : (
                <Text className={s.subtitle}>
                  {user?.city || "No city"}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                Country
              </Text>
              {edit ? (
                <SelectWrapper
                  name={"countryCode"}
                  className={"mb-4 input "}
                  placeholder={"GH"}
                  options={countryOptions}
                  isLoading={userLoading}
                  value={countryOptions.find(
                    (i) => i.value === methods.getValues("countryCode")
                  )}
                  onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as any;
                    if (user) {
                      setValue("countryCode", value.value);
                    }
                  }}
                />
              ) : (
                <Text className={"gi-subtitle"}>
                  {user
                    ? `${user.country?.name || "No Country"}`
                    : userError && !userLoading
                      ? "Error"
                      : "..."}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                Phone Number
              </Text>
              {edit ? (
                <TextWrapper
                  name={"phone"}
                  color="primary"
                  placeholder="024xxxxxxxx"
                  className={"mb-4"}
                />
              ) : (
                <Text Component={"label"} className={"gi-subtitle"}>
                  {user
                    ? `${user.contacts.find((e: any) => e.type === "mobile")
                      ?.value || "No Phone Number"
                    }`
                    : userError && !userLoading
                      ? "Error"
                      : "..."}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={s.label}>
                Email
              </Text>
              {edit ? (
                <TextWrapper
                  name={"email"}
                  type={"email"}
                  color="primary"
                  placeholder="chelseaarday@gmail.com"
                  className={"mb-4"}
                />
              ) : (
                <Text Component={"label"} className={"gi-subtitle"}>
                  {user
                    ? `${user.contacts.find((e: any) => e.type !== "mobile")
                      ?.value || "No Email Address"
                    }`
                    : userError && !userLoading
                      ? "Error"
                      : "..."}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </div>
  );
};

export default Information;