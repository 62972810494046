/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Text, Textfield } from "@components/common";
import cn from "classnames";
import React, { useState } from "react";
import { useAppDispatch } from "hooks/appHooks";
import { FORGOT_PASSWORD } from "store/actions/types";
import { useNavigate } from "react-router-dom";

interface Props {}

const Default: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      await dispatch({ type: FORGOT_PASSWORD, email });
      setEmail("");
      navigate("/auth/login"); // Adjust this path as needed
    } catch (error) {
      // If there's an error, we don't do anything as per the requirement
      console.error("Error in forgot password:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className={"authForm reset-password"}>
      <Text Component="h2" className={"header"}>
        Forgot Your Password?
      </Text>
      <Box col className={"content"}>
        <Text Component="h2" className={"subtitle"}>
          Enter your primary contact and we'll send you instructions on how to
          reset your password.
        </Text>
        <Textfield
          className={cn("input")}
          type={"email"}
          label={"Email or Phone"}
          placeholder={"Enter email address or phone number"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          label={isLoading ? "Processing..." : "Continue"}
          pill
          className={"button mt-5 lg:mt-16"}
          onClick={handleContinue}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
};

export default Default;
