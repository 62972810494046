import "@styles/colors.css";
import "@styles/global.css";
import "@styles/modal.css";
import "@styles/typography.css";
import "@styles/tables.css";

import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getAppRoutes, getAuthRoutes } from "config";
import AppPage from "./pages/app";
import Auth from "./pages/auth";
import Landing from "pages/index";
import Loader from "components/loader";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { AuthProvider } from "contexts/AuthProvider";
import configureStore from "store/index";
import NotificationProvider from "components/NotiStack";
import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { QueryClient, QueryClientProvider } from "react-query";
import InvitationResponse from "./pages/InvitationResponse";
import CustomRouter from "@utils/CustomRouter";
import { HIDE_TOAST, SHOW_TOAST } from "store/actions/types";
import { toast as toastify } from "react-toastify";
import { ReactQueryDevtools } from 'react-query/devtools'; // Import the React Query Devtools

function App() {
  const { store, persistor } = configureStore();

  const [queryClient] = useState(() => new QueryClient());
  const BrowserRoutes = () => {

    const { role } = useAppSelector((state) => state.auth);
    const { type, toast } = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (type === SHOW_TOAST) {
        toastify(toast.message, {
          type: toast.variant,
          toastId: toast.message,
          className: "!absolute !top-[10px] !min-w-[420px] !right-[10px]",
          onClose() {
            dispatch({ type: HIDE_TOAST });
          },
        });
      }
    }, [type, toast]);

    return (
      <Routes>
        <Route path="*" element={<Loader message="Error Page Not Found" />} />
        <Route
          path="/invitation-response/:id"
          element={<InvitationResponse />}
        />
        <Route path={`/`} element={<Landing />}>
          {getAuthRoutes().map((route) => {
            const Component: React.ElementType = route.component;
            const subRoutes = route.subRoutes;
            return (
              <Route key={route.path} path={`${route.path}/*`} element={<Component />}>
                {subRoutes &&
                  subRoutes.map((r) => {
                    const SubComponent: React.ElementType = r.component;
                    return <Route path={r.path} element={<SubComponent />} />;
                  })}
              </Route>
            );
          })}
        </Route>
        <Route path={`/auth/*`} element={<Auth />}>
          <Route path="" element={<Navigate to="login" replace />} />
          {getAuthRoutes().map((route) => {
            const Component: React.ElementType = route.component;
            const subRoutes = route.subRoutes;
            return (
              <Route key={route.path} path={`${route.path}/*`} element={<Component />}>
                {subRoutes &&
                  subRoutes.map((r) => {
                    const SubComponent: React.ElementType = r.component;
                    return <Route key={r.path} path={`${r.path}`} element={<SubComponent />} />;
                  })}
              </Route>
            );
          })}
        </Route>
        {role && role !== "" && (
          <Route path={`/app`} element={<AppPage />}>
            <Route path="" element={<Navigate to="dashboard" replace />} />
            {getAppRoutes(role).map((route) => {
              const Component: React.ElementType = route.component;
              const subRoutes = route.subRoutes;
              return (
                <Route key={route.path} path={`${route.path}`} element={<Component />}>
                  {subRoutes &&
                    subRoutes.map((r) => {
                      const SubComponent: React.ElementType = r.component;
                      return <Route path={r.path} element={<SubComponent />} />;
                    })}
                </Route>
              );
            })}
          </Route>
        )}
      </Routes>
    );
  };

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Suspense fallback={<Loader />}>
              <div className="App">
                <CustomRouter>
                  <BrowserRoutes />
                </CustomRouter>
              </div>
            </Suspense>
            <NotificationProvider />
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} /> {/* Add the Devtools here */}
        </QueryClientProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;


// Cannot read properties of undefined (reading 'value')
// TypeError: Cannot read properties of undefined (reading 'value')
//     at http://localhost:3000/static/js/bundle.js:38463:33
//     at commitHookEffectListMount (http://localhost:3000/static/js/bundle.js:111382:30)
//     at commitPassiveMountOnFiber (http://localhost:3000/static/js/bundle.js:112875:17)
//     at commitPassiveMountEffects_complete (http://localhost:3000/static/js/bundle.js:112847:13)
//     at commitPassiveMountEffects_begin (http://localhost:3000/static/js/bundle.js:112837:11)
//     at commitPassiveMountEffects (http://localhost:3000/static/js/bundle.js:112827:7)
//     at flushPassiveEffectsImpl (http://localhost:3000/static/js/bundle.js:114710:7)
//     at flushPassiveEffects (http://localhost:3000/static/js/bundle.js:114663:18)
//     at http://localhost:3000/static/js/bundle.js:114478:13
//     at workLoop (http://localhost:3000/static/js/bundle.js:212225:38)
// ERROR
// Cannot read properties of undefined (reading 'value')
// TypeError: Cannot read properties of undefined (reading 'value')
//     at http://localhost:3000/static/js/bundle.js:38463:33
//     at commitHookEffectListMount (http://localhost:3000/static/js/bundle.js:111382:30)
//     at commitPassiveMountOnFiber (http://localhost:3000/static/js/bundle.js:112875:17)
//     at commitPassiveMountEffects_complete (http://localhost:3000/static/js/bundle.js:112847:13)
//     at commitPassiveMountEffects_begin (http://localhost:3000/static/js/bundle.js:112837:11)
//     at commitPassiveMountEffects (http://localhost:3000/static/js/bundle.js:112827:7)
//     at flushPassiveEffectsImpl (http://localhost:3000/static/js/bundle.js:114710:7)
//     at flushPassiveEffects (http://localhost:3000/static/js/bundle.js:114663:18)
//     at http://localhost:3000/static/js/bundle.js:114478:13
//     at workLoop (http://localhost:3000/static/js/bundle.js:212225:38)